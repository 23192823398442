import React from 'react';
import PropTypes from 'prop-types';
import styles from '../about-us.module.scss';
import Text from '../../../components/text/text';
import Card from '../../../components/card/card';
// import Image from '../../../components/image/image';
import Markdown from 'react-markdown';

const TeamMember = ({ key, member }) => {
  const image = member.image && member.image.filepath ? require(`../../../${member.image.filepath}`) : member.image.url;
  return (
    <div key={key} className={styles['team-member']}>
      <Card
        header={(
          <div className={styles.name}>
            <Text.Heading level={5}><Markdown source={member.name} /></Text.Heading>
          </div>
        )}
        body={(
          <div className={styles.image}>
            <img src={image} />
          </div>
        )}
        footer={(
          <div className={styles.designation}>
            <Text.Body size='large'><Markdown source={member.designation} /></Text.Body>
          </div>
        )}
      />
    </div>
  );
};

TeamMember.propTypes = {
  member: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    designation: PropTypes.string.isRequired,
  }),
  key: PropTypes.string
};

export default TeamMember;
