import React from 'react';
import PropTypes from 'prop-types';

import Text from '../text/text';

import styles from './loader.module.scss';
import loader from '../../images/blockx-loading.svg';

const Loader = ({ icon, message, outline }) => (
  <div className={`${styles.loader} ${outline ? styles.outline : ''}`}>
    <div className={styles.image}>
      <img src={icon} alt="" />
    </div>
    <div className={styles.message}>
      <Text.Heading level={4}>{message}</Text.Heading>
    </div>
  </div>
);

Loader.displayName = 'Loader';
Loader.defaultProps = {
  icon: loader,
  message: '',
  outline: false
};
Loader.propTypes = {
  icon: PropTypes.any,
  message: PropTypes.string,
  outline: PropTypes.bool
};

export default Loader;
