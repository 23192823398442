import React from 'react';
import PropTypes from 'prop-types';
import styles from '../contact-us.module.scss';
import Text from '../../../components/text/text';
import Markdown from 'react-markdown';

const Detail = ({ detail }) => (
  <div className={styles.line}>
    <Text.Heading level={3} color='white'>
      <Markdown source={detail.title} />
    </Text.Heading>
    <div className={styles.content}>
      <Text.Body color='white'>
        <Markdown source={detail.content} />
      </Text.Body>
    </div>
  </div>
);

Detail.propTypes = {
  detail: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
  })
};

export default Detail;
