import * as actions from '../actions.js';

describe('BurgerMenu Actions', () => {
  describe('toggle', () => {
    it('should dispatch TOGGLE_MENU when triggered', async () => {
      const expectedActions = [
        {
          type: 'TOGGLE_MENU',
          payload: { isOpen: true }
        }
      ];

      const store = global.mockStore({
        burgerMenu: {
          isOpen: false
        }
      });
      await store.dispatch(actions.toggle());
      expect(store.getActions()).toEqual(expectedActions);
    });
  });
});
