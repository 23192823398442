import React from 'react';
import { shallow } from 'enzyme';
import InputError from '../input-error';

import inputErrors from '../../../../utils/input-errors';

describe('InputError', () => {
  const props = {
    errors: [inputErrors.required],
    currentError: inputErrors.required.key
  };
  const wrapper = shallow(<InputError {...props} />);

  it('should render', () => {
    expect(wrapper).toMatchSnapshot();
  });
});
