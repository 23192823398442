import AboutUs from './about-us.container';

export default [
  {
    name: 'about-us',
    path: '/about-us',
    component: AboutUs,
    isSecure: false
  }
];
