import { faLinkedin, faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';

export const getIcon = title => {
  switch(title) {
  case 'linkedIn':
    return faLinkedin;
  case 'twitter':
    return faTwitter;
  case 'github':
    return faGithub;
  default:
    return title;
  }
};
