import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Footer from './footer';

export const mapStateToProps = state => ({
  isLessThanDesktop: state.browser.lessThan.desktop
});

const mapDispatchToProps = {
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer);
