import React from 'react';
import PropTypes from 'prop-types';
import styles from './home.module.scss';
import Wrapper from '../../components/wrapper/wrapper';
import Markdown from 'react-markdown';
import Hero from './components/hero';
import ClientServices from './components/client-services/client-services';
import HowWeWork from './components/how-we-work/how-we-work';
import Clients from './components/clients/clients';
import Loader from '../../components/loader/loader';

import Text from '../../components/text/text';
import Form from '../contact-us/components/form';

const Home = ({ hero, clientServices, howWeWork, clients, push, isMobile, help, contactForm: { form, isLoading, success }, updateField, sendMessage }) => {
  if(!hero && !clientServices && !howWeWork && !clients) {
    return (
      <Loader />
    );
  } else {
    return (
      <div className={styles.home}>
        <Wrapper>
          <Hero hero={hero} />
          <ClientServices clientServices={clientServices} />
        </Wrapper>
        <Wrapper>
          <div className={styles.content}>
            <HowWeWork howWeWork={howWeWork} />
          </div>
        </Wrapper>
        <Wrapper>
          <div className={styles.content}>
            <Clients clients={clients} push={push} isMobile={isMobile} />
          </div>
        </Wrapper>
        <Wrapper>
          <div className={styles.content}>
            <div className={styles.help}>
              <Text.Heading><Markdown source={help.title} /></Text.Heading>
              <div style={{textAlign:'justify', paddingTop:10}}>
                <Text.Body className={styles.description}><Markdown escapeHtml={false} source={help.description} /></Text.Body>
              </div>
            </div>
            <Form
              updateField={updateField}
              sendMessage={sendMessage}
              isLoading={isLoading}
              form={form}
              success={success}
            />
          </div>
        </Wrapper>
      </div>
    );
  }
};

Home.propTypes = {
  hero: PropTypes.object,
  clientServices: PropTypes.object,
  howWeWork: PropTypes.object,
  clients: PropTypes.object,
  push: PropTypes.func,
  isMobile: PropTypes.bool,
  updateField: PropTypes.func,
  contactForm: PropTypes.object,
  sendMessage: PropTypes.func
};

export default Home;
