import Home from './home.container';

export default [
  {
    name: 'home',
    path: '/',
    component: Home,
    isSecure: false
  }
];
