import React from 'react';
import PropTypes from 'prop-types';
import styles from './card.module.scss';

const Card = ({header, body, footer}) => (
  <div className={styles.card}>
    {header && <div className={styles['card-header']}>{header}</div>}
    <div className={styles['card-body']}>{body}</div>
    <div className={styles['card-footer']}>{footer}</div>
  </div>
);

Card.propTypes = {
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  body: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  footer: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default Card;
