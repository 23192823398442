import React from 'react';
import PropTypes from 'prop-types';
import styles from '../input.module.scss';

const Label = ({
  id, label, color, className,
}) => (
  <label
    htmlFor={id}
    className={`${styles.label} ${styles[color]} ${className}`}
  >
    {label}
  </label>
);

Label.displayName = 'Label';
Label.defaultProps = {
  className: '',
  color: 'darkest-blue',
};
Label.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  color: PropTypes.oneOf(['darkest-blue', 'rose', 'white']),
  className: PropTypes.string,
};

export default Label;
