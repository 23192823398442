import { connect } from 'react-redux';
import { updateField, sendMessage } from './actions';
import ContactUs from './contact-us';

export const mapStateToProps = state => ({
  ...state.contactUs
});

export const mapDispatchToProps = {
  updateField,
  sendMessage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactUs);
