import React from 'react';
import PropTypes from 'prop-types';
import styles from './button.module.scss';

const Button = ({
  title,
  size,
  width,
  onClick,
  color,
  toTop,
  className,
  type,
  disabled,
}) => (
  <button
    type={type}
    className={`${styles.button} ${disabled ? styles.disabled : ''} ${
      styles[color]
    } ${styles[size]} ${styles[width]} ${className}`}
    onClick={
      toTop
        ? event => {
          window.scrollTo(0, 0);
          return onClick(event);
        }
        : onClick
    }
  >
    {title}
  </button>
);

Button.displayName = 'Button';
Button.defaultProps = {
  title: '',
  color: 'fuchsia',
  toTop: false,
  className: '',
  type: 'button',
  disabled: false,
  size: 'regular',
};
Button.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['blue', 'fuchsia']),
  toTop: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'regular', 'large']),
  width: PropTypes.oneOf(['full', 'three-quarter']),
};

export default Button;
