import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from '../contact-us.module.scss';
import Input from '../../../components/input/input';
import Button from '../../../components/button/button';

class Form extends Component {
  render() {
    const { form, updateField, isLoading, sendMessage } = this.props;
    return (
      <div className={styles.form} ref={r => this.contactRef = r}>
        <div className={styles['form-group']}>
          <Input type='text' id='name' value={form.name} className={styles.input} label='Name:' onInputChange={(value, error) => updateField('name', value, error)} />
          <Input type='text' id='email' value={form.email} className={styles.input} label='Email:' onInputChange={(value, error) => updateField('email', value, error)} />
        </div>
        <div className={styles['form-group']}>
          <Input type='text' id='phone' value={form.phone} className={styles.input} label='Phone Number:' onInputChange={(value, error) => updateField('phone', value, error)} />
          <Input type='text' id='subject' value={form.subject} className={styles.input} label='Subject:' onInputChange={(value, error) => updateField('subject', value, error)} />
        </div>
        <div className={styles['form-group']}>
          <Input isRequired={false} type='textarea' rows={10} cols={53} id='message' value={form.message} label='Message:' onInputChange={(value, error) => updateField('message', value, error)} />
        </div>
        <div className={styles.actions}>
          <Button title={isLoading ? 'Sending...' : 'Send Message'} size='large' width='three-quarter' onClick={() => sendMessage(this.contactRef)} />
        </div>
      </div>
    );
  }
}

Form.propTypes = {
  form: PropTypes.object,
  updateField: PropTypes.func,
  sendMessage: PropTypes.func,
  isLoading: PropTypes.bool,
  success: PropTypes.bool
};

export default Form;
