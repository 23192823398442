import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store.js';
import { ConnectedRouter } from 'connected-react-router';

import App from './app.container';

const redux = configureStore();

export default render(
  <Provider store={redux.store}>
    <ConnectedRouter history={redux.history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
