import Resources from './resources';

export default [
  {
    name: 'resources',
    path: '/resources',
    component: Resources,
    isSecure: false
  }
];
