import ContactUs from './contact-us.container';

export default [
  {
    name: 'contact-us',
    path: '/contact-us',
    component: ContactUs,
    isSecure: false
  }
];
