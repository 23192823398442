import React from 'react';
import { shallow } from 'enzyme';
import Toaster from '../toaster.js';

const MockToast = () => <div>Super Toast</div>;

describe('Toaster', () => {
  const mockedProps = {
    isToasterShown: true,
    toast: <MockToast />,
    position: {},
    timer: 2000,
    hideToaster: () => {}
  };
  const wrapper = shallow(<Toaster {...mockedProps} />);

  it('should render', () => {
    expect(wrapper).toMatchSnapshot();
  });

  it('should return nothing if the toaster is not shown', () => {
    wrapper.setProps({ isToasterShown: false });
    wrapper.update();
    expect(wrapper.text()).toEqual('');
  });
});
