import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import styles from './about-us.module.scss';
import TeamMember from './components/team-member';
import Wrapper from '../../components/wrapper/wrapper';
import Text from '../../components/text/text';
import Loader from '../../components/loader/loader';
import { Helmet } from 'react-helmet';

class AboutUs extends React.Component {
  render() {
    const { ourStory, teamMembers, currentOpenings, isMobile, pageTitle } = this.props;
    if(ourStory && teamMembers && currentOpenings) {
      return (
        <Wrapper>
          <Helmet title={pageTitle} />
          <div className={styles['about-us']}>
            <div className={styles['our-story']}>
              <div className={styles.title}>
                <Text.Heading><Markdown source={ourStory.title} /></Text.Heading>
              </div>
              <div className={styles.description}>
                <Text.Body><Markdown source={ourStory.description} /></Text.Body>
              </div>
            </div>
            <div className={styles['team-members']}>
              <div className={styles.title}>
                <Text.Heading><Markdown source={teamMembers.title} /></Text.Heading>
              </div>
              <div className={styles.members}>
                {
                  teamMembers.members.map(member => (
                    <TeamMember member={member} key={member.title} />
                  ))
                }
              </div>
            </div>
            <div className={styles['current-openings']}>
              <div className={styles.join}>
                <Text.Heading className={styles.title}><Markdown source={currentOpenings.title} /></Text.Heading>
                <Text.Body className={styles.description}><Markdown source={currentOpenings.description} /></Text.Body>
              </div>
              <div className={styles.openings}>
                <Text.Heading level={3} className={styles.title}>Current Openings</Text.Heading>
                <div className={styles.jobs}>
                  <div className={styles.headers}>
                    <Text.Heading level={4}>Position</Text.Heading>
                    {!isMobile && <Text.Heading level={4}>Specialty</Text.Heading>}
                    <Text.Heading level={4}>Location</Text.Heading>
                  </div>
                  {
                    currentOpenings.openings.map((currentOpening) => (
                      <div key={currentOpening.position} className={styles.job}>
                        <div className={styles.position}>
                          <Text.Body size='large' weight='semi-bold'><Markdown source={currentOpening.position} /></Text.Body>
                          <Text.Body size='small' weight='bold'><Markdown source={`( ${currentOpening.level} )`} /></Text.Body>
                        </div>
                        {!isMobile && (
                          <div className={styles.specialty}>
                            <Text.Body size='large' weight='semi-bold'><Markdown source={currentOpening.specialty} /></Text.Body>
                          </div>
                        )}
                        <div className={styles.location}>
                          <Text.Body size='large' weight='semi-bold'><Markdown source={currentOpening.location} /></Text.Body>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      return (
        <Loader />
      );
    }
  }
}

AboutUs.propTypes = {
  ourStory: PropTypes.object,
  teamMembers: PropTypes.object,
  currentOpenings: PropTypes.object,
  isMobile: PropTypes.bool,
  pageTitle: PropTypes.string
};

export default AboutUs;
