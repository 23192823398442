import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';

// import Button from '../button/button';
import Text from '../text/text';

import styles from './footer.module.scss';

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.wrapper}>
      <div className={styles.social}>
        <a href="https://www.linkedin.com/company/blockx-labs/" target="_blank" rel="noopener noreferrer">
          <div className={styles.icon}>
            <FontAwesomeIcon icon={faLinkedin} />
          </div>
        </a>
        <a href="https://twitter.com/BlockxLabs" target="_blank" rel="noopener noreferrer">
          <div className={styles.icon}>
            <FontAwesomeIcon icon={faTwitter} />
          </div>
        </a>
        <a href="https://github.com/blockxlabs" target="_blank" rel="noopener noreferrer">
          <div className={styles.icon}>
            <FontAwesomeIcon icon={faGithub} />
          </div>
        </a>
      </div>
      <div className={styles.menu}>
        <Link to='/' onClick={() => window.scrollTo(0,0)}>
          <div className={styles.item}>
            Home
          </div>
        </Link>
        <a href="https://medium.com/@blockxlabs?utm_source=main-website" target="_blank" rel="noopener noreferrer">
          <div className={styles.item}>
            Blog
          </div>
        </a>
        <Link to='/about-us' onClick={() => window.scrollTo(0,0)}>
          <div className={styles.item}>
            About
          </div>
        </Link>
        <Link to='/contact-us' onClick={() => window.scrollTo(0,0)}>
          <div className={styles.item}>
            Contact
          </div>
        </Link>
      </div>
      <div className={styles.copyright}>
        <Text.Body size='small' weight='bold'>&copy; {new Date().getFullYear()}, All Rights Reserved | BlockX Labs</Text.Body>
      </div>
    </div>
  </div>
);

export default Footer;
