import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import Text from '../../../../components/text/text';
import Button from '../../../../components/button/button';
import Testimonial from './components/testimonial';
import Image from './components/image';
import Markdown from 'react-markdown';

import styles from '../../home.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class Clients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: null,
      testimonials: null,
      currentSlide: 0,
    };
  }
  componentDidMount() {
    this.setState({
      images: this.imageSlider,
      testimonials: this.testimonialSlider
    });
  }

  render() {
    const { clients, push, isMobile } = this.props;
    const { images, testimonials, currentSlide } = this.state;
    return (
      <div className={`${styles.section} ${styles['clients-and-partners']}`} id={clients.elementId}>
        <Text.Heading level={1}><Markdown source={clients.title} /></Text.Heading>
        <div className={styles.description}>
          <Text.Body>
            <Markdown source={clients.description} />
          </Text.Body>
        </div>
        <div className={styles.clients}>
          <div className={styles.images}>
            <Slider
              asNavFor={testimonials}
              ref={slider => (this.imageSlider = slider)}
              slidesToShow={isMobile ? 1 : 3}
              swipeToSlide
              focusOnSelect
              autoplay
              autoplaySpeed={4000}
              dots={!isMobile}
              centerMode
              beforeChange={(current, next) => this.setState({ currentSlide: next })}
              afterChange={current => this.setState({ currentSlide: current })}
            >
              {clients.testimonials.map((client, index) => (
                <Image key={index} client={client} active={index === currentSlide} />
              ))}
            </Slider>
          </div>
          <Slider
            asNavFor={images}
            ref={slider => (this.testimonialSlider = slider)}
          >
            {clients.testimonials.map((client, index) => (
              <Testimonial key={index} client={client} />
            ))}
          </Slider>
        </div>
        <div className={styles.actions}>
          <Button
            title='View All'
            toTop
            width='three-quarter'
            onClick={() => push('/clients-and-partners')}
          />
        </div>
      </div>
    );
  }
}

Clients.propTypes = {
  clients: PropTypes.object.isRequired,
  push: PropTypes.func,
  isMobile: PropTypes.bool
};

export default Clients;
