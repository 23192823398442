import React from 'react';
import PropTypes from 'prop-types';

import styles from '../input.module.scss';

const TextareaInput = ({
  id,
  value,
  rows,
  cols,
  onChange,
  onBlur,
  placeholder,
  showError
}) => (
  <textarea
    id={id}
    name={id}
    rows={rows}
    cols={cols}
    value={value}
    onChange={({ target: { value: inputValue } }) => onChange(inputValue)}
    onBlur={onBlur}
    placeholder={placeholder}
    className={`${showError ? styles.error : ''}`}
  />
);

TextareaInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  showError: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  rows: PropTypes.number,
  cols: PropTypes.number
};

export default TextareaInput;
