import Toaster from './toaster.js';
import { hideToaster } from './actions.js';
import { connect } from 'react-redux';

export const mapStateToProps = state => ({
  ...state.toaster
});

const mapDispatchToProps = {
  hideToaster
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Toaster);
