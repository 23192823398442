import React from 'react';
import PropTypes from 'prop-types';
import styles from '../contact-us.module.scss';
import _ from 'underscore.string';
import Text from '../../../components/text/text';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIcon } from '../helpers.js';

const SocialLink = ({ title, link }) => {
  const icon = getIcon(title);
  return (
    <div className={styles['social-link']}>
      <a target='_blank' rel='noopener noreferrer' href={link}>
        <Text.Heading color='white' level={typeof icon !== 'string' ? 1 : 4}>
          {
            typeof icon !== 'string' ? <FontAwesomeIcon icon={icon} /> : _.titleize(title)
          }
        </Text.Heading>
      </a>
    </div>
  );
};

SocialLink.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string
};

export default SocialLink;
