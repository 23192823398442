import React from 'react';
import PropTypes from 'prop-types';
import styles from '../clients.module.scss';
import Text from '../../../components/text/text';
import Markdown from 'react-markdown';

const Resource = ({ resource }) => (
  <div className={styles.resource}>
    <Text.Body color='white'><Markdown source={resource} /></Text.Body>
  </div>
);

Resource.propTypes = {
  resource: PropTypes.string.isRequired
};

export default Resource;
