import * as actionTypes from './action-types.js';

export const initialState = {
  isToasterShown: false,
  toast: null,
  position: {
    top: 0,
    left: 0,
    width: 0,
    height: 0
  },
  timer: 5000
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.SHOW_TOASTER:
    return {
      ...state,
      ...{
        isToasterShown: true,
        toast: action.toast,
        position: action.position,
        timer: action.timer
      }
    };
  case actionTypes.HIDE_TOASTER:
    return {
      ...state,
      ...{
        isToasterShown: false,
        toast: null
      }
    };
  default:
    return state;
  }
};

export default reducer;
