import { connect } from 'react-redux';
import Products from './products';

export const mapStateToProps = state => ({
  ...state.products
});

const mapDisptachToProps = {};

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(Products);
