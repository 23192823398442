import Clients from './clients.container';
import Client from './client.container';

export default [
  {
    name: 'client',
    path: '/clients-and-partners/:slug',
    component: Client,
    isSecure: false
  },
  {
    name: 'clients',
    path: '/clients-and-partners',
    component: Clients,
    isSecure: false
  },
];
