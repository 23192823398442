import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png';
import bigLogo from '../../images/logo2x.png';
import styles from './header.module.scss';

import HeaderMobile from './components/header-mobile.container';
import HeaderDesktop from './components/header-desktop';

const Header = ({ isLessThanDesktop }) => (
  <div className={styles.header}>
    <div className={styles.logo}>
      <Link to='/'>
        <img
          alt="BlockX Labs"
          width={isLessThanDesktop ? '100%' : '40%'}
          height='auto'
          src={logo}
          srcSet={`${logo} 768w, ${bigLogo} 1024w, ${bigLogo} 1130w`}
        />
      </Link>
    </div>
    { isLessThanDesktop
      ? <HeaderMobile />
      : <HeaderDesktop />
    }
  </div>
);

Header.propTypes = {
  isLessThanDesktop: PropTypes.bool
};

export default Header;
