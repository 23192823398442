import React from 'react';
import styles from './scroll-button.module.scss';
import ScrollTop from 'react-scrolltop-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

const ScrollButton = () => (
  <ScrollTop
    distance={100}
    breakpoint={3000}
    icon={<FontAwesomeIcon icon={faChevronUp} />}
    className={styles['scroll-button']}
  />
);

export default ScrollButton;
