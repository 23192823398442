import React from 'react';
import PropTypes from 'prop-types';

import Text from '../../../../components/text/text';
import ClientService from './components/client-service';
import Markdown from 'react-markdown';

import styles from '../../home.module.scss';

const ClientServices = ({ clientServices }) => (
  <div className={styles.services}>
    <div className={styles.header}>
      <div className={styles.title}>
        <Text.Heading><Markdown source={clientServices.title} /></Text.Heading>
      </div>
      <div className={styles.description}>
        <div className={styles.wrapper}>
          <Text.Body>
            <Markdown source={clientServices.description} />
          </Text.Body>
        </div>
      </div>
    </div>
    <div className={styles.cards}>
      {clientServices.services.map(service => (
        <ClientService key={service.title} service={service} color={service.color} />
      ))}
    </div>
  </div>
);

ClientServices.propTypes = {
  clientServices: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    services: PropTypes.array
  })
};

export default ClientServices;
