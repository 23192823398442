import React from 'react';
import PropTypes from 'prop-types';

import Text from '../../../components/text/text';
import Markdown from 'react-markdown';

import styles from '../home.module.scss';
import building from '../../../images/banner.png';

const Hero = ({ hero }) => (
  <div className={styles.hero}>
    <Text.Heading level={4} color='darkestGrey'>
      <Markdown source={hero.subtitle} />
    </Text.Heading>
    <Text.Heading level={2}>
      <Markdown source={hero.title} />
    </Text.Heading>
    <div className={styles.image}>
      <img src={building} style={{width: '100%', height: 'auto'}} />
    </div>
  </div>
);

Hero.propTypes = {
  hero: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  })
};

export default Hero;
