import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../app.module.scss';


const Resource = ({ resource }) => (
  <div style={{paddingTop:'5%'}}>
    <div style={{paddingTop:10}}> <img style={{width:'100%',height:'auto'}} src={resource.image} /></div>
    <div style={{textAlign:'center', paddingTop:'1%'}}> <button className={styles.primary + ' ' + styles.long}>Download</button> </div>
  </div>

);

Resource.propTypes = {
  resource: PropTypes.object.isRequired,
  isEven:PropTypes.bool
};

export default Resource;
