import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Router from './router.js';

// routes
import home from '../views/home/home.routes.js';
import products from '../views/products/products.routes.js';
import resources from '../views/resources/resources.routes.js';
import aboutUs from '../views/about-us/about-us.routes.js';
import contactUs from '../views/contact-us/contact-us.routes.js';
import clients from '../views/clients/clients.routes.js';

export const mapStateToProps = () => ({
  routes: [
    ...home,
    ...products,
    ...resources,
    ...aboutUs,
    ...contactUs,
    ...clients,
  ]
});

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Router)
);
