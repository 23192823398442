import React from 'react';
import PropTypes from 'prop-types';
import styles from './toaster.module.scss';

const Toaster = ({ isToasterShown, toast, position, timer }) => {
  if (!isToasterShown) {
    return null;
  }

  return (
    <div
      className={styles.toaster}
      style={{
        top: position.top,
        left: position.left,
        width: position.width,
        height: position.height,
        animationDelay: `${timer / 2000}s`,
        animationDuration: `${timer / 2000}s`
      }}
    >
      {toast}
    </div>
  );
};

Toaster.displayName = 'Toaster';
Toaster.propTypes = {
  isToasterShown: PropTypes.bool,
  toast: PropTypes.any,
  position: PropTypes.object,
  timer: PropTypes.number
};

export default Toaster;
