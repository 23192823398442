import React from 'react';
import * as types from './action-types';
import * as toasterActions from '../../state/toaster/actions';
import * as emailService from '../../services/email-service';

import SuccessToast from './components/success-toast';

const updateFormField = updatedContactForm => ({
  type: types.UPDATE_FIELD,
  updatedContactForm
});

export const updateField = (field, value, error) => {
  return function(dispatch, getState) {
    const contactForm = getState().contactUs.contactForm;
    const form = {
      ...contactForm.form,
      ...{
        [field]: value
      }
    };

    const isValidForm = {
      ...contactForm.isValidForm,
      ...{
        [field]: !error
      }
    };

    const updatedContactForm = {
      ...contactForm,
      ...{
        form,
        reset: false,
        isValidForm
      }
    };

    dispatch(updateFormField(updatedContactForm));
  };
};

const sendMessageLoading = () => ({
  type: types.SEND_MESSAGE_START
});

const sendMessageSuccess = () => ({
  type: types.SEND_MESSAGE_SUCCESS
});

const sendMessageFailure = error => ({
  type: types.SEND_MESSAGE_FAILURE,
  error
});

const resetForm = () => ({
  type: types.RESET_FORM
});

function serialize(obj) {
  let serialized = '';
  for (let variable in obj)
  {
    if(serialized != '') serialized += '&';
    serialized += variable + '=' + encodeURIComponent(obj[variable]);
  }

  return serialized;
}

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const sendMessage = (contactRef) => async (dispatch, getState) => {
  dispatch(sendMessageLoading());
  const contactForm = getState().contactUs.contactForm;
  console.log(contactRef.offsetTop);
  try {
    await timeout(100);
    await emailService.send(serialize(contactForm.form));
    dispatch(sendMessageSuccess());
    dispatch(resetForm());
    return dispatch(toasterActions.sendToast(
      <SuccessToast />,
      {
        top: (contactRef.offsetTop - 30),
        left: contactRef.offsetLeft,
        width: contactRef.offsetWidth,
        height: 'auto'
      },
      5000
    ));
  } catch (e) {
    dispatch(sendMessageFailure(e.message));
  }
};
