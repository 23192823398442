import React from 'react';
import styles from './resources.module.scss';
import resourceData from '../../constants/resources.json';
import Resource from './components/resource';
import Text from '../../components/text/text';

//import QueueAnim from 'rc-queue-anim';

class Resources extends React.Component {

  makeRow(content){
    let row=[];
    row.push(<div style={{display:'flex', width:'100%'}} key={row.length+1}>
      {content}
    </div>
    );
    return row;
  }
  resourceList(resourceList) {
    let content = [];
    let table=[];
    resourceList.forEach((resource, i) =>{
      let column=( <div style={{width:'20%'}}>
        <Resource key={resource.title} resource={resource} />
      </div>
      );
      if((i+1) % 5 === 0 ){
        content.push(column);
        table.push(
          this.makeRow(content)
        );
        content=[];
      }else{
        content.push(column);
      }
    });
    return (
      <div>
        {table}
      </div>
    );
  }
  render(){
    const resourceList=resourceData.resources;

    return (
      <div className={styles.resources}>
        <div className={styles.content}>
          <div style={{paddingTop:'3%'}}>
            <Text.Heading level={1}>{resourceData.title}</Text.Heading>
            <div style={{textAlign:'left',paddingTop:10}}>
              <Text.Body>{resourceData.description}</Text.Body>
            </div>
          </div>
          { resourceList !== undefined &&
            this.resourceList(resourceList)
          }

        </div>
      </div>
    );
  }
}

export default Resources;
