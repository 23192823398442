import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

import Text from '../../components/text/text';
import Resource from './components/resource';
import Wrapper from '../../components/wrapper/wrapper';

import styles from './clients.module.scss';

const Client = ({ clients, match }) => {
  const { params: { slug } } = match;
  if (slug) {
    const currentClient = clients[slug].details;
    return (
      <Wrapper>
        <div className={styles.client}>
          <div className={styles.hero}>
            <Text.Heading level={1}><Markdown source={currentClient.name} /></Text.Heading>
            <img src={currentClient.imageOne} alt="Hero Image" />
          </div>
          <div className={styles.resources}>
            <Resource resource={currentClient.users} />
            <Resource resource={currentClient.delivery} />
            <Resource resource={currentClient.resources} />
          </div>
          <div className={styles.project}>
            <div className={styles.info}>
              <div className={styles.title}>
                <Text.Heading level={3}><Markdown source={currentClient.problem.title} /></Text.Heading>
              </div>
              <div className={styles.description}><Markdown source={currentClient.problem.description} /></div>
            </div>
            <div className={styles.info}>
              <div className={styles.title}>
                <Text.Heading level={3}><Markdown source={currentClient.technicalChallenges.title} /></Text.Heading>
              </div>
              <div className={styles.description}><Markdown source={currentClient.technicalChallenges.description} /></div>
            </div>
            <div className={styles.info}>
              <div className={styles.title}>
                <Text.Heading level={3}><Markdown source={currentClient.solution.title} /></Text.Heading>
              </div>
              <div className={styles.description}><Markdown source={currentClient.solution.description} /></div>
            </div>
          </div>
          <div className={styles.blockquote}>
            <img src={currentClient.blockquote.image} />
            <div className={styles.quote}>
              <FontAwesomeIcon icon={faQuoteLeft} className={styles.block} />
              <Text.Heading level={4}><Markdown source={currentClient.blockquote.quote} /></Text.Heading>
            </div>
          </div>
          <div className={styles.more}>
            {Object.keys(currentClient.moreInfo).map((key, index) => {
              const info = currentClient.moreInfo[key];
              return (
                <div className={styles.paragraph} key={`${key}-${index}`}>
                  <Text.Body><Markdown source={info} /></Text.Body>
                </div>
              );
            })}
          </div>
        </div>
      </Wrapper>
    );
  } else {
    return null;
  }
};

Client.propTypes = {
  clients: PropTypes.object,
  match: PropTypes.object
};

export default Client;
