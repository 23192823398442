import { connect } from 'react-redux';
import Header from './header';

export const mapStateToProps = state => ({
  isLessThanDesktop: state.browser.lessThan.desktop
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
