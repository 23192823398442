import { EMAIL_REGEX } from './regex';

const inputErrors = {
  required: {
    key: 'empty',
    message: 'This field is required.',
    isError: value => !value || value.length === 0,
  },
  validEmail: {
    key: 'valid-email',
    message: 'Must be a valid email.',
    isError: email => !EMAIL_REGEX.test(email),
  },
};

export default inputErrors;
