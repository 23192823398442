import React from 'react';
import { shallow } from 'enzyme';
import DefaultInput from '../default-input';

describe('DefaultInput', () => {
  const props = {
    id: 'test',
    placeholder: '',
    isDisabled: false,
    type: 'text',
    value: '',
    showError: false,
    onChange: jest.fn(),
    onBlur: jest.fn()
  };

  const wrapper = shallow(<DefaultInput {...props} />);

  it('should render', () => {
    expect(wrapper).toMatchSnapshot();
  });

  it('should handle change', () => {
    wrapper.find('input').simulate('change', { target: { value: 'hello' } });
    expect(props.onChange).toHaveBeenCalledWith('hello');
  });

  it('should handle error', () => {
    wrapper.setProps({
      ...props,
      ...{
        showError: true
      }
    });

    expect(wrapper).toMatchSnapshot();
  });
});
