import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styles from './products.module.scss';
import Product from './components/product';
import Wrapper from '../../components/wrapper/wrapper';
import Text from '../../components/text/text';

//import QueueAnim from 'rc-queue-anim';

class Products extends React.Component {
  render(){
    const { pageTitle, title, description, products } = this.props;
    console.log(pageTitle, title, description, products);

    return (
      <Wrapper>
        <Helmet title={pageTitle} />
        <div className={styles.products}>
          <div className={styles.content}>
            <div>
              <Text.Heading level={1}>{title}</Text.Heading>
              <div>
                <Text.Body>{description}</Text.Body>
              </div>
            </div>
            { products !== undefined &&
              products.map((product,index) => (
                <Product key={product.title} product={product} isEven={(index%2===0)} />
              ))
            }

          </div>
        </div>
      </Wrapper>
    );
  }
}

Products.propTypes = {
  pageTitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  products: PropTypes.array
};

export default Products;
