import React from 'react';
import styles from '../header.module.scss';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const HeaderDesktop = () => (
  <div className={styles.menu}>
    <HashLink
      to='/#how-we-work'
      scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'nearest'})}
    >
      <div className={styles.item}>
        How We Work
      </div>
    </HashLink>
    <HashLink
      to='/#clients-and-partners'
      scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'end'})}
    >
      <div className={styles.item}>
        Clients &amp; Partners
      </div>
    </HashLink>
    {/*<Link to='/products'>
      <div className={styles.item}>
        Our Products
      </div>
    </Link>*/}
    {/*<Link to='/resources'>
      <div className={styles.item}>
        Resources
      </div>
    </Link>*/}
    <a href="https://medium.com/@blockxlabs?utm_source=main-website" target="_blank" rel="noopener noreferrer">
      <div className={styles.item}>
        Blog
      </div>
    </a>
    <Link to='/about-us'>
      <div className={styles.item}>
        About
      </div>
    </Link>
    <Link to='/contact-us'>
      <div className={styles.item}>
        Contact
      </div>
    </Link>
  </div>
);

export default HeaderDesktop;
