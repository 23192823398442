import React from 'react';
import PropTypes from 'prop-types';

import Text from '../../../../components/text/text';
import Step from './components/step';
import Markdown from 'react-markdown';

import styles from '../../home.module.scss';

const HowWeWork = ({ howWeWork }) => (
  <div className={styles.section} id={howWeWork.elementId}>
    <Text.Heading level={1}><Markdown source={howWeWork.title} /></Text.Heading>
    <div className={styles.description}>
      <div className={styles.wrapper}>
        <Text.Body className={styles.text}>
          <Markdown source={howWeWork.description} />
        </Text.Body>
      </div>
    </div>
    {
      howWeWork.steps.map(step => (
        <Step key={step.title} step={step} />
      ))
    }
  </div>
);

HowWeWork.propTypes = {
  howWeWork: PropTypes.object.isRequired
};

export default HowWeWork;
