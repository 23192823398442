import { connect } from 'react-redux';
import { toggle } from '../../../state/burger-menu/actions.js';
import MobileHeader from './header-mobile.js';

export const mapStateToProps = state => ({
  isOpen: state.burgerMenu.isOpen,
});

const mapDispatchToProps = {
  toggle,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileHeader);
