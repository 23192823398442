import React from 'react';
import PropTypes from 'prop-types';
import styles from '../products.module.scss';
import Button from '../../../components/button/button';
import Text from '../../../components/text/text';


const Product = ({ product }) => (
  <div className={styles.product}>
    <div classame={styles.image}>
      <img src={product.image} />
    </div>
    <div className={styles.content}>
      <Text.Heading>{product.title}</Text.Heading>
      <div className={styles.markup}>
        <Text.Body>{product.description}</Text.Body>
      </div>
      <div className={styles.actions}>
        <Button
          title='Click to view website'
          size='small'
          width='three-quarter'
        />
      </div>
    </div>
  </div>
);

Product.propTypes = {
  product: PropTypes.object.isRequired,
};

export default Product;
