import React from 'react';
import PropTypes from 'prop-types';
import styles from '../header.module.scss';
import { slide as Menu } from 'react-burger-menu';
import { decorator as reduxBurgerMenu } from 'redux-burger-menu';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { HashLink } from 'react-router-hash-link';

const elStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '24px',
    height: '20px',
    right: '20px',
    top: '20px'
  },
  bmBurgerBars: {
    background: '#FFFFFF'
  },
  bmCrossButton: {
    height: '30px',
    width: '30px'
  },
  bmCross: {
    background: '#000000',
  },
  bmMenuWrap: {
    width: '100%',
    top: '0px',
    paddingLeft: '20%',
  },
  bmMenu: {
    background: '#FFFFFF',
    fontSize: '1.15em',
    color: '#FFFFFF'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '30px'
  },
  bmItem: {
    display: 'block',
    color: '#FFFFFF'
  },
  bmOverlay: {
    background: '#29324D'
  }
};

const HeaderMobile = ({ isOpen, toggle }) => (
  <div className={styles.menu}>
    <div className={styles.bars}>
      <span onClick={toggle}><FontAwesomeIcon icon={faBars} /></span>
    </div>
    <Menu isOpen={isOpen} right styles={elStyles} customBurgerIcon={false} htmlClassName={null}>
      <HashLink
        to='/#how-we-work'
        scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'nearest'})}
        onClick={toggle}
      >
        <div className={styles.item}>
          How We Work
        </div>
      </HashLink>
      <HashLink
        to='/#clients-and-partners'
        scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'end'})}
        onClick={toggle}
      >
        <div className={styles.item}>
          Clients &amp; Partners
        </div>
      </HashLink>
      {/*<Link to='/products' onClick={toggle}>
        <div className={styles.item}>
          Our Products
        </div>
      </Link>*/}
      {/*<Link to='/resources' onClick={toggle}>
        <div className={styles.item}>
          Resources
        </div>
      </Link>*/}
      <a href="https://medium.com/@blockxlabs?utm_source=main-website" target="_blank" rel="noopener noreferrer">
        <div className={styles.item}>
          Blog
        </div>
      </a>
      <Link to='/about-us' onClick={toggle}>
        <div className={styles.item}>
          About
        </div>
      </Link>
      <Link to='/contact-us' onClick={toggle}>
        <div className={styles.item}>
          Contact
        </div>
      </Link>
    </Menu>
  </div>
);

HeaderMobile.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func
};

export default reduxBurgerMenu(HeaderMobile);
