import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { updateField, sendMessage } from '../contact-us/actions';
import Home from './home';

export const mapStateToProps = state => ({
  ...state.home,
  ...state.contactUs,
  isMobile: state.browser.is.mobile
});

const mapDispatchToProps = {
  push,
  updateField,
  sendMessage
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Home));
