import React from 'react';
import PropTypes from 'prop-types';
import styles from './wrapper.module.scss';

const Wrapper = ({children}) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      {children}
    </div>
  </div>
);

Wrapper.propTypes = {
  children: PropTypes.node
};

export default Wrapper;
