import React, { Component } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import Router from './router/router.container';
import Header from './components/header/header.container';
import ScrollButton from './components/scroll-button/scroll-button';
import styles from './app.module.scss';
import Footer from './components/footer/footer.container';
import Toaster from './state/toaster/toaster.container.js';
import { Helmet } from 'react-helmet';


if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-97869064-3');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

class App extends Component {
  render() {
    const { title } = this.props;
    return (
      <div className={styles.app} ref={this.scrollRef}>
        <Helmet
          title={title}
        />
        <Header />
        <main className={styles.main}>
          <Router/>
        </main>
        <Footer />
        <ScrollButton />
        <Toaster />
      </div>
    );
  }
}

App.propTypes = {
  title: PropTypes.string
};

export default App;
