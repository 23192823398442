import React from 'react';

import Text from '../../../components/text/text';

import styles from '../contact-us.module.scss';

const SuccessToast = () => (
  <div className={styles.toast}>
    <Text.Body size='regular' color='white'>
      Thank you for contacting us! A member of our team will be in touch within 1 - 2 business days
    </Text.Body>
  </div>
);

export default SuccessToast;
