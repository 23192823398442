import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import { Helmet } from 'react-helmet';

import Wrapper from '../../components/wrapper/wrapper';
import Text from '../../components/text/text';
import Detail from './components/detail';
import SocialLink from './components/social-link';
import Form from './components/form';
import Loader from '../../components/loader/loader';

import styles from './contact-us.module.scss';
import map from '../../images/map.png';

class ContactUs extends React.Component {
  render(){
    const { pageTitle, businessInfo, social, help, contactForm: { form, isLoading, success }, updateField, sendMessage } = this.props;
    if(!businessInfo && !social && !help ) return (
      <Loader />
    );
    return (
      <Wrapper>
        <Helmet title={pageTitle} />
        <div className={styles['contact-us']}>
          <Text.Heading>Contact Us</Text.Heading>
          <div className={styles['map-and-address']}>
            <div className={styles.address}>
              {Object.keys(businessInfo).map(key => (
                <Detail key={businessInfo[key].title} detail={businessInfo[key]} />
              ))}
            </div>
            <div className={styles.map}>
              <a href="https://goo.gl/maps/dqz2UTVHjZB2" target='_blank' rel='noopener noreferrer'><img src={map} /></a>
            </div>
          </div>
          <div className={styles.social}>
            <Text.Heading><Markdown source={social.title} /></Text.Heading>
            <div className={styles['social-links']}>
              {
                Object.keys(social.links).filter(l => social.links[l] !== '').map((link, index) => (
                  <SocialLink key={`${link}-${index}`} title={link} link={social.links[link]} />
                ))
              }
            </div>
          </div>
          <div className={styles.help}>
            <Text.Heading><Markdown source={help.title} /></Text.Heading>
            <div style={{textAlign:'justify', paddingTop:10}}>
              <Text.Body className={styles.description}><Markdown escapeHtml={false} source={help.description} /></Text.Body>
            </div>
          </div>
          <Form
            isLoading={isLoading}
            form={form}
            sendMessage={sendMessage}
            updateField={updateField}
            success={success}
          />
        </div>
      </Wrapper>
    );
  }
}

ContactUs.propTypes = {
  businessInfo: PropTypes.object,
  help: PropTypes.object,
  social: PropTypes.object,
  contactForm: PropTypes.object,
  sendMessage: PropTypes.func,
  updateField: PropTypes.func,
  pageTitle: PropTypes.string
};

export default ContactUs;
