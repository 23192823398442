import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

import Text from '../../../../../components/text/text';

import styles from '../../../home.module.scss';

const ClientService = ({ service, color }) => {
  const image = service.image && service.image.filepath
    ? require(`../../../../../${service.image.filepath}`)
    : service.image.url;
  return (
    <div className={styles['service-container']}>
      <div className={styles.service}>
        <div className={`${styles.front} ${styles[color]}`}>
          <div className={styles.image}>
            <img src={image} />
          </div>
          <div className={styles.title}>
            <Text.Heading level={3} weight='regular'>
              <Markdown source={service.title} />
            </Text.Heading>
          </div>
        </div>
        <div className={`${styles.back} ${styles[color]}`}>
          <Text.Body size='small'>
            <Markdown source={service.description} />
          </Text.Body>
        </div>
      </div>
    </div>
  );
};

ClientService.propTypes = {
  service: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
  }),
  color: PropTypes.string.isRequired
};

export default ClientService;
