import React from 'react';
import * as actionTypes from '../action-types.js';
import * as actions from '../actions.js';

const MockToast = () => <div>Super Toast</div>;

const closeOnTime = async time =>
  new Promise(resolve => {
    setTimeout(resolve, time);
  });

describe('hideToaster', () => {
  it('should dispatch HIDE_TOASTER', () => {
    const expectedActions = [{ type: actionTypes.HIDE_TOASTER }];

    const store = global.mockStore();

    store.dispatch(actions.hideToaster());
    expect(store.getActions()).toEqual(expectedActions);
  });
});

describe('sendToast', () => {
  it('should dispatch SHOW_TOASTER and HIDE_TOASTER after defined timeout', async () => {
    const timeout = 500; // we can't test the default timeout as it's too long for jest
    const toast = <MockToast />;
    const position = {};

    const expectedActions = [
      { type: actionTypes.SHOW_TOASTER, toast, position, timer: timeout }
    ];

    const expectedActionsFuture = [{ type: actionTypes.HIDE_TOASTER }];

    const store = global.mockStore();

    store.dispatch(actions.sendToast(toast, position, timeout));
    expect(store.getActions()).toEqual(expectedActions);

    await closeOnTime(timeout);

    expect(store.getActions()).toEqual([
      ...expectedActions,
      ...expectedActionsFuture
    ]);
  });
});
