import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Clients from './clients';

const mapStateToProps = state => ({
  clients: state.clients
});
const mapDispatchToProps = {
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Clients);
