import { connect } from 'react-redux';
import Client from './client';

export const mapStateToProps = state => ({
  clients: state.clients.clients
});

export default connect(
  mapStateToProps,
  null
)(Client);
