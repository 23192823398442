import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from '../../../home.module.scss';
import Text from '../../../../../components/text/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import Markdown from 'react-markdown';

const Testimonial = ({ client }) => (
  <Fragment>
    <div className={styles.testimonial}>
      <Text.Heading className={styles.blockquote}><FontAwesomeIcon icon={faQuoteLeft} /></Text.Heading>
      <Text.Body>
        <Markdown source={client.testimonial} />
      </Text.Body>
    </div>
    <div className={styles.author}>
      <Text.Heading level={5}>&#8212; <Markdown source={client.author} /></Text.Heading>
    </div>
  </Fragment>
);

Testimonial.propTypes = {
  client: PropTypes.shape({
    image: PropTypes.string.isRequired,
    testimonial: PropTypes.string.isRequired
  })
};

export default Testimonial;
