import * as types from './action-types';
import moment from 'moment';

export const initialState = {
  pageTitle: 'BlockX Labs | Contact Us',
  contactForm: {
    isLoading: false,
    success: false,
    error: null,
    form: {
      name:'',
      email:'',
      phone:'',
      subject:'',
      message:'',
      timestamp: moment.utc().format('YYYY-MM-DD HH:MM:SS')
    },
    isValidForm: {
      name: false,
      email: false,
      phone: false,
      subject: false,
      message: false
    }
  }
};

const contactUs = (state = initialState, action) => {
  switch (action.type) {
  case types.UPDATE_FIELD:
    return {
      ...state,
      contactForm: action.updatedContactForm
    };
  case types.SEND_MESSAGE_START:
    return {
      ...state,
      contactForm: {
        ...state.contactForm,
        isLoading: true
      }
    };
  case types.SEND_MESSAGE_SUCCESS:
    return {
      ...state,
      contactForm: {
        ...state.contactForm,
        error: null,
        success: true,
        isLoading: false
      }
    };
  case types.SEND_MESSAGE_FAILURE:
    return {
      ...state,
      contactForm: {
        ...state.contactForm,
        success: false,
        error: action.error,
        isLoading: false
      }
    };
  case types.RESET_FORM:
    return {
      ...state,
      contactForm: initialState.contactForm
    };
  default:
    return state;
  }
};

export default contactUs;
