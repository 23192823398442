import React from 'react';
import { shallow } from 'enzyme';
import Input from '../input';

import inputErrors from '../../../utils/input-errors';
import { getCurrentError } from '../helpers';

describe('Input', () => {
  const props = {
    id: 'test',
    type: 'text',
    forceValidation: false,
    isDisabled: false,
    isRequired: true,
    reset: false,
    extraErrors: [],
    className: '',
    label: '',
    value: '',
    placeholder: '',
    onInputChange: jest.fn()
  };
  const wrapper = shallow(<Input {...props}/>);

  it('should render', () => {
    expect(wrapper).toMatchSnapshot();
  });

  it('should render with emtpy errors state value if not required', () => {
    const newProps = {
      ...props,
      ...{
        isRequired: false
      }
    };
    const notRequired = shallow(<Input {...newProps} />);
    expect(notRequired.state().errors).toEqual([]);
  });

  it('should handle reset properly', () => {
    const getDerivedStateFromProps = Input.getDerivedStateFromProps({
      ...props,
      ...{
        reset: true
      }
    }, {
      errors: [
        inputErrors.required
      ],
      currentError: false,
      hasBlurred: false,
      isVirgin: true
    });

    expect(getDerivedStateFromProps).toEqual({
      errors: [
        inputErrors.required
      ],
      hasBlurred: false,
      isVirgin: true,
      currentError: false
    });
  });

  it('should handle forceValidation properly', () => {
    const getDerivedStateFromProps = Input.getDerivedStateFromProps({
      ...props,
      ...{
        forceValidation: true
      }
    }, {
      errors: [
        inputErrors.required
      ],
      currentError: false,
      hasBlurred: false,
      isVirgin: true
    });

    expect(getDerivedStateFromProps).toEqual({
      errors: [
        inputErrors.required
      ],
      hasBlurred: true,
      isVirgin: false,
      currentError: getCurrentError(props.value, [
        inputErrors.required
      ])
    });
  });

  it('should handle blur', () => {
    wrapper.find('DefaultInput').simulate('blur');
    expect(props.onInputChange).toHaveBeenCalledWith('', 'empty');
  });

  it('should handle change', () => {
    wrapper.find('DefaultInput').simulate('change', 'hello');
    expect(props.onInputChange).toHaveBeenCalledWith('hello', false);
  });
});
