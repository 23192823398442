import React from 'react';
import { shallow } from 'enzyme';
import Label from '../label';

describe('Label', () => {
  const props = {
    id: 'test',
    label: 'this is a label',
    color: 'white',
    className: ''
  };
  const wrapper = shallow(<Label {...props} />);

  it('should render', () => {
    expect(wrapper).toMatchSnapshot();
  });
});
