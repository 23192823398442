import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { responsiveStoreEnhancer } from 'redux-responsive';
import createReducer from './reducers.js';
import axios from 'axios';
import * as asyncInitialState from 'redux-async-initial-state';

const env = process.env.NODE_ENV || 'development';
const config = require('../config/config.json')[env];

let store;
const history = process.env.NODE_ENV === 'test' ? createMemoryHistory() : createBrowserHistory();

const loadStore = (getCurrentState) => {
  return new Promise(resolve => {
    axios.get(config.settingsUrl, {
      mode: 'cors'
    })
      .then(response => response.data)
      .then(response => {
        const { app, home, clients, products, aboutUs, contactUs } = response;
        const currentState = getCurrentState();
        resolve({
          ...currentState,
          app: {
            ...currentState.app,
            ...app
          },
          home: {
            ...currentState.home,
            ...home,
          },
          clients: {
            ...currentState.clients,
            ...clients,
          },
          products: {
            ...currentState.products,
            ...products,
          },
          aboutUs: {
            ...currentState.aboutUs,
            ...aboutUs,
          },
          contactUs: {
            ...currentState.contactUs,
            ...contactUs,
          },
        });
      });
  });
};

export const configureStore = () => {
  const middlewares = Array.prototype.concat(
    [],
    [thunk, routerMiddleware(history), asyncInitialState.middleware(loadStore)],
    process.env.NODE_ENV === 'development' ? [createLogger()] : [],
  );

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancer = composeEnhancers(
    applyMiddleware(...middlewares),
    responsiveStoreEnhancer
  );

  store = createStore(createReducer(history), enhancer);
  store.asyncReducers = {};

  return {
    store,
    history
  };
};
