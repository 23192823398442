import { combineReducers } from 'redux';
import { createResponsiveStateReducer } from 'redux-responsive';
import { connectRouter } from 'connected-react-router';
import { reducer as burgerMenu } from 'redux-burger-menu';
import * as asyncInitialState from 'redux-async-initial-state';

// reducers
import app from '../state/app/reducer';
import home from '../views/home/reducer';
import clients from '../views/clients/reducer';
import contactUs from '../views/contact-us/reducer';
import aboutUs from '../views/about-us/reducer';
import products from '../views/products/reducer';
import toaster from '../state/toaster/reducer';

const createReducer = (history) => {
  return asyncInitialState.outerReducer(combineReducers({
    router: connectRouter(history),
    burgerMenu,
    browser: createResponsiveStateReducer({
      mobile: 768,
      tablet: 1024,
      desktop: 1130
    }),
    app,
    home,
    clients,
    contactUs,
    aboutUs,
    products,
    toaster,
    asyncInitialState: asyncInitialState.innerReducer,
  }));
};

export default createReducer;
