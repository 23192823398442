import React from 'react';
import reducer, { initialState } from '../reducer.js';
import * as actionTypes from '../action-types.js';

const MockToast = () => <div>Super Toast</div>;

const openToasterState = {
  isToasterShown: true,
  toast: <MockToast />,
  position: {
    top: 10,
    left: 10,
    width: 100,
    height: 200
  },
  timer: 2000
};

const closeToasterState = {
  isToasterShown: false,
  toast: null,
  position: {
    top: 10,
    left: 10,
    width: 100,
    height: 200
  },
  timer: 2000
};

describe('reducer', () => {
  it('should return the initial state', () => {
    expect(reducer(undefined, {})).toEqual(initialState);
  });

  it('should handle TOASTER/SHOW_TOASTER', () => {
    const toast = <MockToast />;

    const openToasterAction = {
      type: actionTypes.SHOW_TOASTER,
      toast,
      position: {
        top: 10,
        left: 10,
        width: 100,
        height: 200
      },
      timer: 2000
    };
    expect(reducer(initialState, openToasterAction)).toEqual(openToasterState);
  });

  it('should handle TOASTER/HIDE_TOASTER', () => {
    const closeToasterAction = { type: actionTypes.HIDE_TOASTER };
    expect(reducer(openToasterState, closeToasterAction)).toEqual(
      closeToasterState
    );
  });
});
