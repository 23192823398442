import Products from './products.container';

export default [
  {
    name: 'products',
    path: '/products',
    component: Products,
    isSecure: false
  }
];
