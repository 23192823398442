import React from 'react';
import PropTypes from 'prop-types';
import styles from './clients.module.scss';
import Client from './components/client';
import Wrapper from '../../components/wrapper/wrapper';
import Loader from '../../components/loader/loader';
import Text from '../../components/text/text';
import Button from '../../components/button/button';
import Markdown from 'react-markdown';
import { Helmet } from 'react-helmet';

//import QueueAnim from 'rc-queue-anim';

class Clients extends React.Component {
  render(){
    const { clients: clientsData, push } = this.props;
    if(!clientsData) return ( <Loader /> );
    const { title, ctaTitle, description, clients, pageTitle } = clientsData;
    return (
      <Wrapper>
        <Helmet title={pageTitle} />
        <div className={styles['clients-and-partners']}>
          <div className={styles.content}>
            <div style={{paddingTop:'3%'}}>
              <div className={styles.title}>
                <Text.Heading level={1}><Markdown source={title} /></Text.Heading>
              </div>
              <div className={styles.description}>
                <Text.Body><Markdown source={description} /></Text.Body>
              </div>
            </div>
            <div className={styles.clients}>
              {
                clients && Object.keys(clients).map((client, index) => {
                  return (
                    <Client key={`${clients[client].title}-${index}`} push={push} slug={client} client={clients[client]} />
                  );
                })
              }
            </div>
            {ctaTitle && (
              <Button
                title={ctaTitle}
                width='full'
                size='large'
                onClick={() => push('/contact-us')}
              />
            )}
          </div>
        </div>
      </Wrapper>
    );
  }
}

Clients.propTypes = {
  push: PropTypes.func.isRequired,
  clients: PropTypes.object.isRequired,
};

export default Clients;
