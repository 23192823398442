import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LazyLoader from 'react-lazy-load';

import styles from './image.module.scss';

const loaded = {};

class Image extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: loaded[props.src]
    };

    this.onLoad = this.onLoad.bind(this);
  }

  onLoad() {
    loaded[this.props.src] = true;
    this.setState(() => ({ loaded: true }));
  }

  render() {
    const { src, className } = this.props;
    return (
      <LazyLoader
        debounce={false}
        throttle={250}
      >
        <img
          src={src}
          className={`${className} ${this.state.loaded ? styles.loaded : styles.loading}`}
          onLoad={this.onLoad} />
      </LazyLoader>
    );
  }
}

Image.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string
};

export default Image;
