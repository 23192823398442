import React from 'react';
import PropTypes from 'prop-types';
import styles from '../clients.module.scss';
import Card from '../../../components/card/card';
import Text from '../../../components/text/text';


const Client = ({ client }) => (
  <div className={styles.client}>
    <Card
      body={(
        <div className={styles.image}>
          <img src={client.image} />
        </div>
      )}
      footer={(
        <div className={styles.title}>
          <Text.Heading level={5}>{client.title}</Text.Heading>
        </div>
      )}
    />
  </div>

);

Client.propTypes = {
  client: PropTypes.object.isRequired,
};

export default Client;
