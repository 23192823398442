import React from 'react';
import PropTypes from 'prop-types';
import styles from '../text.module.scss';

const Body = ({
  size,
  weight,
  children,
  className,
  color,
}) => (
  <div
    className={`${styles[`body-${size}`]} ${styles[weight]} ${className} ${
      styles[color]
    }`}
  >
    {children}
  </div>
);

Body.displayName = 'Body';
Body.propTypes = {
  size: PropTypes.oneOf(['tiny', 'small', 'regular', 'large']),
  weight: PropTypes.oneOf(['light', 'regular', 'semi-bold', 'bold']),
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['black', 'white'])
};
Body.defaultProps = {
  size: 'regular',
  weight: 'regular',
  children: '',
  className: '',
  color: 'black',
};

export default Body;
