import axios from 'axios';
const env = process.env.NODE_ENV || 'development';
const config = require('../config/config.json')[env];

export const send = async formData => {
  console.log(formData);
  return axios({
    url: config.googleScriptUrl,
    method: 'post',
    data: formData
  });
};
