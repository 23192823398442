import { getCurrentError } from '../helpers';
import inputErrors from '../../../utils/input-errors';

describe('getCurrentError', () => {
  const errors = [inputErrors.required, inputErrors.validEmail];
  it('should return false if no errors provided', () => {
    expect(getCurrentError('test', [])).toEqual(false);
  });

  it('should get current error', () => {
    expect(getCurrentError('test@mail.com', errors)).toEqual(false);
  });

  it('should return correct error key', () => {
    expect(getCurrentError('test@', errors)).toEqual('valid-email');
  });
});
