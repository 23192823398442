import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../home.module.scss';

const Image = ({ client, active }) => {
  const image = client.image && client.image.filepath
    ? require(`../../../../../${client.image.filepath}`)
    : client.image.url;
  return (
    <div className={`${styles.image} ${active ? styles.active : ''}`}>
      <img src={image} />
    </div>
  );
};

Image.propTypes = {
  client: PropTypes.shape({
    image: PropTypes.string.isRequired,
    testimonial: PropTypes.string.isRequired
  }),
  active: PropTypes.bool
};

export default Image;
