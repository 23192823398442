import * as actionTypes from './action-types.js';

const showToaster = (toast, position, timer) => ({
  type: actionTypes.SHOW_TOASTER,
  toast,
  position,
  timer
});

export const hideToaster = () => ({ type: actionTypes.HIDE_TOASTER });

export const sendToast = (toast, position, timer) =>
  async function(dispatch) {
    dispatch(showToaster(toast, position, timer));
    setTimeout(() => {
      dispatch(hideToaster());
    }, timer ? timer : 5000);
  };
