import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../home.module.scss';
import Text from '../../../../../components/text/text';
import Markdown from 'react-markdown';

const Step = ({ step }) => {
  const image = step.image && step.image.filepath
    ? require(`../../../../../${step.image.filepath}`)
    : step.image.url;
  return (
    <div className={styles.step}>
      <div className={styles.image}>
        <img src={image} />
      </div>
      <div className={styles.content}>
        <Text.Heading><Markdown source={step.title} /></Text.Heading>
        <div className={styles.markup}>
          <Text.Body className={styles.text}>
            <Markdown source={step.description} />
          </Text.Body>
        </div>
      </div>
    </div>
  );
};

Step.propTypes = {
  step: PropTypes.object.isRequired,
};

export default Step;
