import { Component } from 'react';
import Heading from './components/heading';
import Body from './components/body';

class Text extends Component {
  static Heading = Heading
  static Body = Body
}

export default Text;
