import { initialState } from '../reducer.js';
import { mapStateToProps } from '../toaster.container.js';

describe('mapStateToProps', () => {
  it('should return the login initial state from state', () => {
    const state = {
      toaster: initialState
    };
    expect(mapStateToProps(state)).toEqual(initialState);
  });
});
