import React from 'react';
import PropTypes from 'prop-types';
import styles from '../text.module.scss';

const Heading = ({
  level,
  weight,
  children,
  className,
  color,
}) => {
  const Element = `h${level}`;

  return (
    <Element
      className={`${styles[`heading-${level}`]} ${styles[weight]} ${
        styles[color]
      } ${className}`}
    >
      {children}
    </Element>
  );
};

Heading.displayName = 'Heading';
Heading.propTypes = {
  level: PropTypes.number && PropTypes.oneOf([1, 2, 3, 4, 5]),
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'black',
    'white',
    'lightGrey',
    'mediumGrey',
    'darkestGrey'
  ]),
  weight: PropTypes.oneOf([
    'light',
    'regular',
    'semi-bold',
    'bold'
  ])
};
Heading.defaultProps = {
  level: 2,
  weight: 'bold',
  children: '',
  className: '',
  color: 'black'
};

export default Heading;
